import axios from "axios";
import * as global from "@/globalFunction.js";
import { store } from "@/store/index.js";
import { app } from "@/main";

const methods = global.default.methods;

export default {
  async apiCall(method, endpoint, params = {}, jwt_key = null, crossplatform = false, xApiKey = null, Signature = null) {
    let url = crossplatform ? process.env.VUE_APP_HOST_NAME_MY : process.env.VUE_APP_HOST_NAME;
    let key = jwt_key ?? localStorage.getItem("jwt_key");
    let rtoken = localStorage.getItem("rtoken");

    try {
      let apiCall = axios.create({
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${key}`,
          'RefreshToken': `Bearer ${rtoken}`,
          'X-Api-key': xApiKey,
          'Signature': Signature
        },
      });

      let response;
      if (method === "GET") {
        const api = await apiCall.get(url + endpoint, { params });
        response = api.data;
      } else if (method === "POST") {
        const api = await apiCall.post(url + endpoint, params);
        response = api.data;
      } else if (method === "PUT") {
        const api = await apiCall.put(url + endpoint, params);
        response = api.data;
      } else {
        const api = await apiCall.delete(url + endpoint, { data: params });
        response = api.data;
      }

      return response;
    } catch (error) {
      if (error.request.status == 401) {
        if (store.state.payment.request_id){
          return;
        }
        else if (localStorage.getItem("jwt_key")) {
          console.log(store);
          localStorage.removeItem("jwt_key");
          localStorage.removeItem("rtoken");
          store.dispatch("preferences/setPlatform", 2);
          store.dispatch("auth/initializeAuthState");
          localStorage.removeItem("data");
          localStorage.removeItem("selectedPlat");
          methods.navigateToRoute("SignInView");
        }
      }
      return error.response
        ? error.response.data
        : { message: "Unknown error" };
    }
  },
};
