import VueCookies from "vue-cookies";

const state = {
  theme: localStorage.getItem("user-theme") || "dark-theme",
  language: localStorage.getItem("lang") || "en",
  pair: {
    pair: null,
    base: null,
    quote: null,
  },
  interval: localStorage.getItem("interval") || "1D",
  selected_time: localStorage.getItem("selected_time") || {
    1: 1,
    2: 1,
    3: 1,
  },
  hide: localStorage.getItem("hide") == "false" ? false : true,
  deviceWidth: window.innerWidth,
  showCookieModal: false,
  showSideBar: false,
  setReturnMerchant: false,
  platform: "",
};

const getters = {
  routePrefix: (state, getters, rootState) => {
    if (rootState.auth.isLoggedIn) {
      let owned_platform = rootState.user.platform;

      if (owned_platform == 0) {
        return state.platform == 1 ? "MY" : "GB";
      } else if (owned_platform == 1) {
        return "MY";
      } else if (owned_platform == 2) {
        return "GB";
      } else if (owned_platform == 3) {
        return state.platform == 1 ? "MY" : "GB";
      } else {
        return localStorage.getItem("selectedPlat") == 1 ? "MY" : "GB";
      }
    } else {
      return state.platform && state.platform == 1 ? "MY" : "GB";
    }

    // return "GB";
  },
};

const mutations = {
  SET_THEME(state, theme) {
    state.theme = theme;
    localStorage.setItem("user-theme", theme);
  },

  SET_PAIR(state, { pair, base, quote }) {
    state.pair.pair = pair;
    state.pair.base = base;
    state.pair.quote = quote;
    storeExchangePair(pair, base, quote);
  },

  SET_LANGUAGE(state, language) {
    state.language = language;
    localStorage.setItem("lang", language);
  },

  SET_CHART_INTERVAL(state, interval) {
    state.interval = interval;
    localStorage.setItem("interval", interval);
  },

  SET_FILTER_TIME(state, time) {
    state.selected_time = time;
    localStorage.setItem("selected_time", JSON.stringify(time));
  },

  SET_HIDE(state, hide) {
    state.hide = hide;
    localStorage.setItem("hide", hide);
  },

  SET_DEVICE_WIDTH(state, width) {
    state.deviceWidth = width;
  },

  SET_COOKIE_MODAL(state, modal) {
    state.showCookieModal = modal;
  },

  SET_OPEN_SIDEBAR(state, value) {
    state.showSideBar = value;
  },

  SET_RETURN_MERCHANT(state, value){
    state.setReturnMerchant = value
  },

  SET_PLATFORM(state, platform) {
    state.platform = platform;
    localStorage.setItem("selectedPlat", platform);
  },
};

const actions = {
  initializePreferences({ commit, rootState }) {
    commit(
      "SET_THEME",
      localStorage.getItem("user-theme") || getMediaPreference()
    );

    const exchangePair = getExchangePair();
    commit("SET_PAIR", exchangePair);

    commit("SET_LANGUAGE", localStorage.getItem("lang") || "en");
    commit("SET_CHART_INTERVAL", localStorage.getItem("interval") || "1D");

    let time = localStorage.getItem("selected_time");
    if (time && time != "NaN" && typeof JSON.parse(time) === "object") {
      commit(
        "SET_FILTER_TIME",
        JSON.parse(localStorage.getItem("selected_time"))
      );
    } else {
      commit("SET_FILTER_TIME", {
        1: 0,
        2: 0,
        3: 0,
      });
    }

    commit("SET_HIDE", localStorage.getItem("hide") == "false" ? false : true);

    // let isShowCookiesModal = checkReadCookies();
    commit("SET_COOKIE_MODAL", false);

    if (rootState.auth.isLoggedIn) {
      let platform = localStorage.getItem("selectedPlat");
      commit("SET_PLATFORM", platform ? platform : 2);
    } else {
      commit("SET_PLATFORM", 2);
    }
  },

  showCookieModal({ commit }, modal) {
    commit("SET_COOKIE_MODAL", modal);
  },

  checkCookieModal({ commit }) {
    let isShowCookiesModal = checkReadCookies();
    commit("SET_COOKIE_MODAL", isShowCookiesModal);
  },

  setTheme({ commit }, theme) {
    commit("SET_THEME", theme || getMediaPreference());
  },

  setPair({ commit }, { pair, base, quote }) {
    commit("SET_PAIR", { pair, base, quote });
  },

  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language);
  },

  setChartInterval({ commit }, interval) {
    commit("SET_CHART_INTERVAL", interval);
  },

  setFilterTime({ commit }, time) {
    commit("SET_FILTER_TIME", time);
  },

  setHide({ commit }, hide) {
    commit("SET_HIDE", hide);
  },

  setDeviceWidth({ commit }) {
    commit("SET_DEVICE_WIDTH", window.innerWidth);
  },

  setOpenSidebar({ commit }, value) {
    commit("SET_OPEN_SIDEBAR", value);
  },

  setReturnMerchant({ commit }, value) {
    commit("SET_RETURN_MERCHANT", value);
  },

  setPlatform({ commit, dispatch, rootState }, platform) {
    commit("SET_PLATFORM", platform);
    dispatch("user/refreshToken", null, { root: true }).then(() => {
      if (platform == 2 && rootState.user.kyc_status.toLowerCase() === "approved") {
        dispatch("referral/checkAffUser", "", { root: true });
        dispatch("referral/checkRefUser", "", { root: true });
      } else if (platform == 1 && rootState.user.kyc_status.toLowerCase() === "approved") {
        dispatch("MY_referral/checkAffUser", "", { root: true });
        dispatch("MY_referral/checkRefUser", "", { root: true });
      }
    });
  },
};

// Helper functions to get and save exchange pair
function getExchangePair() {
  const exchangePreferences = localStorage.getItem("pair");
  return exchangePreferences && exchangePreferences != "null"
    ? JSON.parse(exchangePreferences)
    : { pair: null, base: null, quote: null };
}

function storeExchangePair(pair, base, quote) {
  const value = {
    pair: pair,
    base: base,
    quote: quote,
  };
  localStorage.setItem("pair", JSON.stringify(value));
}

function getMediaPreference() {
  const hasDarkPreference = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  if (hasDarkPreference) {
    return "dark-theme";
  } else {
    return "light-theme";
  }
}

function checkReadCookies() {
  let cookies = VueCookies.get("isCookieRead");
  let isShowCookies = false;

  if (cookies) {
    if (cookies == "false") {
      isShowCookies = true;
    }
  } else {
    isShowCookies = true;
  }

  return isShowCookies;
}

export const preferences = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
