<template>
    <v-dialog v-model="isShow" :class="`dialog-modal ${isExchange ? 'exchange-modal' : ''} `" :max-width="maxWidth"
        :isExchange="isExchange === true" :persistent="persistent" @click:outside="!persistent ? $emit('close') : ''">
        <div class="modal-body-custom" :id="modalId">
            <button @click="$emit('close')" class="modal-close-btn" v-if="isCloseBtn">
                <img src="@/assets/images/modal/close-circle.png" width="22px" />
            </button>

            <img :src="require(`@/assets/images/${image}`)" class="modal-image" width="50px" />

            <div class="modal-title" :class="titleClass" v-if="title">
                {{ title }}
            </div>
            <div class="modal-description" :class="preLine ? 'pre-line' : ''" v-if="description">
                <div v-html="description" v-if="description"></div>
                <div>
                    <span v-if="isOurWebsite"><a @click="navigateToRoute('FeesView')"
                            class="secondary-underline-text">{{ `${$t("wallet.ourWebsite")}` }}</a>.</span>
                    <span v-if="isSubmitTicket">{{ `${$t('Withdraw.withdrawalDisabled2')}` }}<a @click="routeToHelpCenter"
                            class="secondary-underline-text">{{ `${$t("Withdraw.withdrawalDisabled3")}` }}</a>{{
                                `${$t('Withdraw.withdrawalDisabled4')}` }}</span>
                </div>

            </div>
            <div class="modal-description" v-if="description2">
                <div v-html="description2"></div>
                <!-- <span v-if="isHelpCenter2"><a @click="routeToHelpCenter" class="secondary-underline-text">{{
                    `${$t("Profile.helpCentre")}` }}</a>.</span> -->
            </div>

            <div class="modal-disclaimer" v-if="disclaimer">
                <div>{{ disclaimer }}</div>
            </div>

            <slot name="description" v-if="!description"></slot>

            <RemarkContainer :icon="remark.icon" :isWarning="remark.isWarning" :text="remark.text" v-if="remark" />
            <div class="txn-details-body-content" v-if="details">
                <!-- EXCHANGE PAIR -->
                <div class="txn-details-row" v-if="details.exchangePair">
                    <div class="txn-details-row-title">{{ $t('Trading.exchangePair') }}</div>
                    <div class="txn-details-row-content">
                        {{ details.exchangePair }}
                    </div>
                </div>

                <!-- EXCHANGE AMOUNT -->
                <div class="txn-details-row" v-if="details.exchangeAmount">
                    <div class="txn-details-row-title">{{ $t('Trading.amount') }}</div>
                    <div class="txn-details-row-content">
                        {{ details.exchangeAmount }}
                    </div>
                </div>

                <!-- CURRENT MARKET PRICE -->
                <div class="txn-details-row" v-if="details.marketPrice">
                    <div class="txn-details-row-title">{{ $t('Trading.currentMarket') }} ({{ details.quoteSymbol }})
                    </div>
                    <div class="txn-details-row-content">
                        {{ details.marketPrice }}
                    </div>
                </div>
            </div>

            <button class="main-btn" @click="$emit('action')" v-if="buttonText">
                {{ buttonText }}
            </button>

            <button class="secondary-btn" @click="$emit('action2')" v-if="buttonText2">
                {{ buttonText2 }}
            </button>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isHelpCenter: { type: Boolean, default: false },
        isHelpCenter2: { type: Boolean, default: false },
        modalId: { type: String },
        dialog: { type: Boolean },
        image: { type: String },
        title: { type: String },
        titleClass: { type: String, default: "" },
        description: { type: String },
        description2: { type: String },
        disclaimer: { type: String },
        remark: { type: Object, default: () => { } },
        buttonText: { type: String },
        buttonText2: { type: String, default: "" },
        isCloseBtn: { type: Boolean, default: true },
        persistent: { type: Boolean, default: false },
        details: { type: Object },
        isExchange: { type: Boolean, default: false },
        link: { type: String },
        isOurWebsite: { type: Boolean, default: false },
        isSubmitTicket: { type: Boolean, default: false },
        maxWidth: {type: String, default: '500px'},
        preLine: { type: Boolean, default: false }
    },

    computed: {
        isShow() {
            return this.dialog;
        }
    },

    methods: {
        routeToHelpCenter() {
            if (this.link) {
                this.openLinkInNewTab(this.link)
            } else {
                this.openLinkInNewTab("https://support.hata.io/support/home");
            }

        }
    }
}
</script>

<style>

#modal-status-requestkyc .modal-description {
    white-space: break-spaces;
}

#modal-buy-sell-faq .modal-description {
    white-space: break-spaces;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
}

#modal-buy-sell-faq .modal-description div:nth-child(2) {
    color: var(--secondary-color);
    cursor: pointer;
    font-weight: 600;
}

#modal-buy-sell-faq .modal-body-custom {
    margin: 0 0 10px 0;
    background-color: var(--gray10);
}
</style>