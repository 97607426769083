import api from "@/assets/api/api.js";
import * as global from "@/globalFunction.js";
let methods = global.default.methods;

const state = {
  pairs: [],
  currentPair: {},
  currentPair_lastPrice: "",
  history_tab: 1,
  history: {
    loading: {
      1: false,
      2: false,
      3: false,
    },

    time: {
      1: 0,
      2: 0,
      3: 0,
    },

    page: {
      1: 1,
      2: 1,
      3: 1,
    },

    isBuy: {
      1: null,
      2: null,
      3: null,
    },

    totalPages: {
      1: 0,
      2: 0,
      3: 0,
    },

    openOrderCount: 0,
  },
  time_filter: {},
  hidePair: false,
};

const mutations = {
  SET_PAIRS(state, pairs) {
    state.pairs = pairs;
  },

  SET_CURRENT_PAIR(state, pair) {
    state.currentPair = pair;
  },

  SET_CURRENT_PAIR_LAST_PRICE(state, last_price) {
    state.currentPair_lastPrice = last_price;
  },

  SET_TIME_FILTER(state, time) {
    state.time_filter = time;
  },

  SET_HISTORY_TAB(state, tab) {
    state.history_tab = tab;
  },

  SET_HISTORY_STATE(state, value) {
    state.history = value;
  },

  SET_HIDE_PAIR(state, value) {
    state.hidePair = value;
  },
};

const actions = {
  initializeHistory({ commit, rootState }) {
    let time = {
      1: methods.getStartDate(1),
      2: methods.getStartDate(7),
      3: methods.getStartDate(31),
      4: methods.getStartDate(90),
    };

    commit("SET_TIME_FILTER", {
      start_time: time,
      end_time: methods.getTodayTime(false),
    });

    commit("SET_HISTORY_TAB", 1);
    commit("SET_HISTORY_STATE", {
      ...state.history,
      time: {
        1: rootState.preferences.selected_time[1],
        2: rootState.preferences.selected_time[2],
        3: rootState.preferences.selected_time[3],
      },
    });
  },

  async getMarketTokens() {
    let response = await api.apiCall("GET", "/orderbook/api/market/tokens");

    if (response.status === "success") {
      let data = response.data != null ? response.data : [];
      return data;
    } else {
      console.error(response.message);
    }
  },

  setCurrentPairInfo({ commit, rootState }) {
    let currentPair = rootState.preferences.pair;
    let filter = state.pairs.filter((i) => i.txpair === currentPair.pair);

    commit("SET_CURRENT_PAIR", filter[0]);
    commit("SET_CURRENT_PAIR_LAST_PRICE", filter[0].price);
  },

  setCurrentPairLastPrice({ commit }, last_price) {
    commit("SET_CURRENT_PAIR_LAST_PRICE", last_price);
  },

  setHidePair({ commit }, hidePair) {
    commit("SET_HIDE_PAIR", hidePair);
  },

  async getPairInfo({ commit }) {
    let response = await api.apiCall("GET", "/orderbook/api/trades/pairinfo");

    if (response.status === "success") {
      let data = response.data != null ? response.data : [];
      commit("SET_PAIRS", data);
      return data;
    } else {
      console.error(response.message);
    }
  },

  async getOrderBook(context, params) {
    let response = await api.apiCall("GET", "/orderbook/api/orderbook", params);

    if (response.status === "success") {
      return {
        asks: response.data.asks != null ? response.data.asks : [],
        bids: response.data.bids != null ? response.data.bids : [],
      };
    } else {
      console.error(response.message);
    }
  },

  async getRecentTrade(context, params) {
    let response = await api.apiCall("GET", "/orderbook/api/trade", params);

    if (response.status === "success") {
      let data = response.data != null ? response.data : [];
      return data;
    } else {
      console.error(response.message);
    }
  },

  async createOrder(context, params) {
    let response = await api.apiCall(
      "POST",
      "/orderbook/jwt/orders/create",
      params
    );

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async cancelOrder(context, params) {
    let response = await api.apiCall(
      "POST",
      "/orderbook/jwt/orders/cancel",
      params
    );

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async cancelAllOrder(context, params) {
    let response = await api.apiCall(
      "POST",
      "/orderbook/jwt/orders/cancel/all",
      params
    );

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  // HISTORY
  setHistoryTab({ commit }, tab) {
    commit("SET_HISTORY_TAB", tab);
  },

  setHistoryState({ commit }, value) {
    commit("SET_HISTORY_STATE", value);
  },

  async getOrderHistory(context, params) {
    let response = await api.apiCall(
      "GET",
      "/orderbook/jwt/users/orders",
      params
    );

    if (response.status === "success") {
      let data = response.data.orders != null ? response.data.orders : [];

      return {
        history: data,
        pages: response.data.pages,
        order_count: response.data.orderCount,
      };
    } else {
      console.error(response.message);
    }
  },

  async getTradeHistory(context, params) {
    let response = await api.apiCall(
      "GET",
      "/orderbook/jwt/trades/history",
      params
    );

    if (response.status === "success") {
      let data = response.data.trades != null ? response.data.trades : [];

      return {
        history: data,
        pages: response.data.pages,
      };
    } else {
      console.error(response.message);
    }
  },
};

export const orderbook = {
  namespaced: true,
  state,
  mutations,
  actions,
};
