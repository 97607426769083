import api from "@/assets/api/api.js";
import myapi from "@/assets/api/api_my.js";
import * as global from "@/globalFunction.js";
import { app } from "@/main";
import VueCookies from "vue-cookies";

let methods = global.default.methods;

let decrypted = localStorage.getItem("data")
  ? JSON.parse(methods.decryptData(localStorage.getItem("data")))
  : {};

const state = {
  id: decrypted.id || "",
  name: decrypted.name || "",
  email: decrypted.email || "",
  referral_id: decrypted.referral_id || "",
  twofa_enabled: decrypted.twofa_enabled || false,
  phone: decrypted.phone || "",
  kyc_status: decrypted.kyc_status || "incomplete",
  receive_news: decrypted.receive_news || true,
  business: decrypted.business || false,
  secure_email: decrypted.secure_email || "",
  platform: decrypted.platform || 0,
  // 0 - no platform
  // 1 - malaysia
  // 2 - global
  // 3 - both
  notification_count: 0,
  showRequest2faModal: false,
  showRequestKycModal: false,
  showHavingTroubleModal: false,
  showRefModal: false,
  can_Login: true,
  can_WCrypto: true,
  can_WFiat: true,
  can_Trade: true,
  showSwitchErrorModal: false,
  firstRef: VueCookies.get("firstRef") ? VueCookies.get("firstRef") : "",
};

const mutations = {
  SET_USER_ID(state, id) {
    state.id = id;
    updateData("id", id);
  },

  SET_NAME(state, name) {
    state.name = name;
    updateData("name", name);
  },

  SET_EMAIL(state, email) {
    state.email = email;
    updateData("email", email);
  },

  SET_REFERRAL_ID(state, ref_id) {
    state.referral_id = ref_id;
    updateData("referral_id", ref_id);
  },

  SET_TWOFA(state, twofa) {
    state.twofa_enabled = twofa;
    updateData("twofa_enabled", twofa);
  },

  SET_PHONE(state, phone) {
    state.phone = phone;
    updateData("phone", phone);
  },

  SET_KYC_STATUS(state, kyc_status) {
    state.kyc_status = kyc_status;
    updateData("kyc_status", kyc_status);
  },

  SET_RECEIVE_NEWS(state, receive_news) {
    state.receive_news = receive_news;
    updateData("receive_news", receive_news);
  },

  SET_BUSINESS(state, business) {
    state.business = business;
    updateData("business", business);
  },

  SET_SECURE_EMAIL(state, secure_email) {
    state.secure_email = secure_email;
    updateData("secure_email", secure_email);
  },

  SET_PLATFORM(state, platform) {
    state.platform = platform;
    updateData("platform", platform);
  },

  SET_NOTIFICATION_COUNT(state, count) {
    state.notification_count = count;
  },

  SET_REQUEST2FA_MODAL(state, modal) {
    state.showRequest2faModal = modal;
  },

  SET_REQUESTKYC_MODAL(state, modal) {
    state.showRequestKycModal = modal;
  },

  SET_HAVINGTROUBLE_MODAL(state, modal) {
    state.showHavingTroubleModal = modal;
  },

  SET_REF_MODAL(state, modal) {
    state.showRefModal = modal;
  },

  SET_PERMISSION(state, status) {
    // 1248 1 login,2 withdraw crypto,4 withdraw fiat,8 trade
    state.can_Login = (status & 1) == 1 ? true : false;
    state.can_WCrypto = ((status >> 1) & 1) == 1 ? true : false;
    state.can_WFiat = ((status >> 2) & 1) == 1 ? true : false;
    state.can_Trade = ((status >> 3) & 1) == 1 ? true : false;
  },

  SET_SWITCH_ERROR(state, modal) {
    state.showSwitchErrorModal = modal;
  },

  SET_FIRST_REF(state, status) {
    state.firstRef = status;
    VueCookies.set("firstRef", status, Infinity);
  },
};

const actions = {
  initializeUser({ commit, dispatch, rootState }) {
    let decrypted = {};
    if (localStorage.getItem("data") != null) {
      decrypted = JSON.parse(methods.decryptData(localStorage.getItem("data")));
    }

    commit("SET_USER_ID", decrypted.id || "");
    commit("SET_NAME", decrypted.name || "");
    commit("SET_EMAIL", decrypted.email || "");
    commit("SET_REFERRAL_ID", decrypted.referral_id || "");
    commit("SET_TWOFA", decrypted.twofa_enabled || false);
    commit("SET_PHONE", decrypted.phone || "");
    commit("SET_KYC_STATUS", decrypted.kyc_status || "incomplete");
    commit("SET_RECEIVE_NEWS", decrypted.receive_news);
    commit("SET_BUSINESS", decrypted.business || false);
    commit("SET_SECURE_EMAIL", decrypted.secure_email || "");
    commit("SET_PLATFORM", decrypted.platform || 0);

    if (
      decrypted.platform != 0 &&
      (rootState.preferences.platform <= 0 ||
        rootState.preferences.platform == "")
    ) {
      if (decrypted.platform == 3) {
        if (localStorage.getItem("selectedPlat")) {
          dispatch(
            "preferences/setPlatform",
            localStorage.getItem("selectedPlat"),
            { root: true }
          );
        } else {
          dispatch("preferences/setPlatform", 2, { root: true });
        }
      } else {
        dispatch("preferences/setPlatform", decrypted.platform, { root: true });
      }
    }
  },

  setPlatform({ commit, dispatch, rootState }, platform) {
    if (platform != 0 && rootState.preferences.platform == "") {
      if (platform == 3) {
        dispatch("preferences/setPlatform", 2, { root: true });
      } else {
        dispatch("preferences/setPlatform", platform, { root: true });
      }
    }
    commit("SET_PLATFORM", platform);
  },

  setBusiness({ commit }, business) {
    commit("SET_BUSINESS", business || false);
  },

  showRequest2faModal({ commit }, modal) {
    commit("SET_REQUEST2FA_MODAL", modal);
  },

  showRequestKycModal({ commit }, modal) {
    commit("SET_REQUESTKYC_MODAL", modal);
  },

  showHavingTroubleModal({ commit }, modal) {
    commit("SET_HAVINGTROUBLE_MODAL", modal);
  },

  showRefModal({ commit }, modal) {
    commit("SET_REF_MODAL", modal);
  },

  showSwitchErrorModal({ commit }, modal) {
    commit("SET_SWITCH_ERROR", modal);
  },

  // PROFILE
  async getProfile({ commit, dispatch, rootState }) {
    let platform = rootState.preferences.platform == 1 ? "MY" : "WW";
    let response = await api.apiCall("GET", "/auth/jwt/users/profile", {
      platform: platform,
    });

    if (response.status === "success") {
      let data = response.data;

      commit("SET_PERMISSION", data.status);

      localStorage.setItem(
        "data",
        methods.encryptData(
          methods.storeProfileObject(
            data.user_id,
            data.fullname,
            data.email,
            data.referral_id,
            data.twofa_enabled,
            data.phone,
            data.kyc_status.toLowerCase(),
            data.receive_news,
            data.business,
            data.secure_email,
            data.platform
          )
        )
      );

      dispatch("initializeUser");
      return data;
    } else {
      throw response;
    }
  },

  async updateReceiveNews({ commit }, params) {
    let response = await api.apiCall(
      "PUT",
      "/auth/jwt/users/receive-news",
      params
    );

    if (response.status === "success") {
      let data = response.data;
      commit("SET_RECEIVE_NEWS", params.receive_news);
      return data;
    } else {
      throw response;
    }
  },

  // PHONE
  async bindphone({ commit }, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/users/setup-phone",
      params
    );

    if (response.status === "success") {
      commit("SET_PHONE", params.new_phone);
    } else {
      throw response;
    }
  },

  // SET SECURE EMAIL (BUSINESS ACC)
  async getSecureEmailOtp(context, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/business/secure-otp/request",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async setSecureEmail({ commit }, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/business/kyc/secret",
      params
    );

    if (response.status === "success") {
      commit("SET_SECURE_EMAIL", params.secure_email);
    } else {
      throw response;
    }
  },

  // TWO FA
  async generateSecretKey() {
    let response = await api.apiCall("POST", "/auth/jwt/totp/generate");

    if (response.status === "success") {
      let data = response.data;
      let email = state.business ? state.secure_email : state.email;

      let qrCodeValue = `otpauth://totp/Hata:${email}?secret=${data.secret}&issuer=Hata&algorithm=SHA1&digits=6&period=30`;
      let secretKey = data.secret;

      return {
        qr_value: qrCodeValue,
        secret: secretKey,
      };
    } else {
      throw response;
    }
  },

  async bindTwoFa({ commit }, params) {
    let response = await api.apiCall("POST", "/auth/jwt/totp/bind", params);

    if (response.status === "success") {
      localStorage.setItem("jwt_key", response.data.token);
      localStorage.setItem("rtoken", response.data.rtoken);
      commit("SET_TWOFA", true);
    } else {
      throw response;
    }
  },

  async unbindTwoFa({ commit }, params) {
    let response = await api.apiCall("POST", "/auth/jwt/totp/unbind", params);

    if (response.status === "success") {
      commit("SET_TWOFA", false);
    } else {
      throw response;
    }
  },

  // CHANGE PASSWORD
  async changePassword(context, params) {
    let response = await api.apiCall("PUT", "/auth/jwt/users/password", params);

    if (response.status !== "success") {
      throw response;
    }
  },

  // REQUEST VERIFICATION CODE
  async requestEmailOtp(context, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/email-otp/request",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async requestPhoneOtp(context, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/sms-otp/request",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  // NOTIFICATION
  async getNotification({ commit }, params) {
    let response = await api.apiCall(
      "GET",
      "/auth/jwt/users/notification",
      params
    );

    if (response.status === "success") {
      commit("SET_NOTIFICATION_COUNT", response.data.unread_count);
      return {
        notification:
          response.data.notifications == null
            ? []
            : response.data.notifications,
        unread_count: response.data.unread_count,
        pages: response.data.pages,
      };
    } else {
      console.error(response.message);
    }
  },

  async readAllNoti() {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/users/notification/read_all"
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async deleteNoti({ commit }, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/users/notification/delete",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async deleteAllNoti() {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/users/notification/delete_all"
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  // API KEY
  async getApiKey() {
    let response = await api.apiCall("GET", "/auth/jwt/api-key");

    if (response.status === "success") {
      return {
        api: response.data.api_key != null ? response.data.api_key : [],
      };
    }
  },

  async generateApiKey(context, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/api-key/generate",
      params
    );

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async deleteApiKey(context, params) {
    let response = await api.apiCall(
      "DELETE",
      "/auth/jwt/api-key/delete",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  // REWARD
  async claimReward(context, params) {
    let response = await api.apiCall(
      "POST",
      "/promotion/jwt/claim/code",
      params
    );

    if (response.status === "success") {
      return response.message;
    } else {
      throw response;
    }
  },

  async refreshToken({ commit, dispatch, rootState }) {
    let response = await api.apiCall("POST", "/auth/jwt/refresh-token");

    if (response.status === "success") {
      if (rootState.auth.isLoggedIn) {
        localStorage.setItem("jwt_key", response.data.token);
        localStorage.setItem("rtoken", response.data.rtoken);

        let data = response.data;
        localStorage.setItem(
          "data",
          methods.encryptData(
            methods.storeProfileObject(
              data.user_id,
              "",
              data.email,
              "",
              data.two_fa_enabled,
              data.phone,
              data.kyc_status.toLowerCase(),
              "",
              data.business,
              data.secure_email,
              data.platform
            )
          )
        );

        dispatch("initializeUser");
      }

      return response.data;
    } else {
      console.error(response.message);
    }
  },

  async addPlatform(context, { params, type }) {
    let response;

    if (type == 1) {
      response = await myapi.apiCall(
        "POST",
        "/identity/jwt/platform/add",
        params
      );
    } else {
      response = await api.apiCall(
        "POST",
        "/identity/jwt/platform/add",
        params
      );
    }

    if (response.status !== "success") {
      throw response;
    }
  },

  async newUserPlatform(context, platform) {
    let response;
    if (platform == "2") {
      response = await api.apiCall("POST", "/identity/jwt/register/platform");
    } else {
      response = await myapi.apiCall("POST", "/identity/jwt/register/platform");
    }

    if (response.status !== "success") {
      throw response;
    }
  },

  // GET DICT LIST
  async getDictList(context, { params, platform = 0 }) {
    let response;
    if (platform == 1) {
      response = await myapi.apiCall("GET", "/identity/api/dict", params);
    } else {
      response = await api.apiCall("GET", "/identity/api/dict", params);
    }
    if (response.status === "success") {
      return response.data;
    } else {
      console.error(response.message);
    }
  },

  async changeFirstRef({ commit }, params) {
    commit("SET_FIRST_REF", params);
  },
};

function updateData(key, value) {
  state[key] = value;

  const storedData =
    localStorage.getItem("data") != null
      ? JSON.parse(methods.decryptData(localStorage.getItem("data")))
      : {};
  storedData[key] = value;

  localStorage.setItem("data", methods.encryptData(JSON.stringify(storedData)));
}

export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
};
