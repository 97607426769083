<template>
    <div class="no-data-container">
        <img src="@/assets/images/wallet/no-transaction.png" width="65px" />
        <div>{{ $t("Trading.noDataHistory") }}</div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>