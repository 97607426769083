<template>
    <div class="main-body" id="verification">
        <div class="main-header">
            <button class="onboarding-back-arrow" @click="$emit('backAction')">
                <v-icon>mdi-arrow-left</v-icon>
            </button>
            <div class="main-header-text">{{ $t("Address.verification") }}</div>
            <button class="info-circle-faq" @click="isShowVerificationFAQ = true">
                <InlineSvg
                    :src="require('@/assets/images/modal/info-circle.svg')"
                    width="25px"
                />
            </button>
        </div>

        <div class="main-body">
            <!-- EMAIL -->
            <TextInput 
                :label="$t('Verification.sendCode')"
                :email="$store.state.user.business ? $store.state.user.secure_email : $store.state.user.email"
                name="emailCode"
                v-model="emailCode"
                type="number"
                :placeholder="$t('Verification.enterVerificationCode')"
                :errorMessage="emailCodeError"
                @reset="emailCode = '', emailCodeError = ''"
                @clearError="emailCodeError = ''"
                :sendCode="true"
                @send="getEmailCode()"
                :rightButtonText="emailBtnText"
                :sendCodeDisabled="emailDisabled"
                :maxLength="6"
                :decimalPlaces="0"
                :isLoading="isLoading"
                
            />

            <!-- TWO FA -->
            <TextInput 
                :label="$t('Verification.googleVerifyCode')"
                name="twoFACode"
                v-model="twoFACode"
                type="number"
                :placeholder="$t('Verification.enterGoogleVerifyCode')"
                :errorMessage="codeError"
                @reset="twoFACode = '', codeError = ''"
                @clearError="codeError = ''"
                :sendCode="true"
                @send="paste()"
                :rightButtonText="$t('Verification.paste')"
                :maxLength="6"
                :decimalPlaces="0"
                
            />

            <button 
                class="main-btn w-85" 
                @click="submit"
                :disabled="submitDisabled"
            > 
                <span v-if="!submitDisabled">{{ $t("Button.Next") }}</span>
                <span class="spinner-border-sm spinner-border" v-else></span>
            </button>

            <button
                @click="openLinkInNewTab('https://support.hata.io/support/tickets/new')"
                class="secondary-btn" 
            >
                {{ $t("Login.trouble") }}
            </button>
        </div>

        <!-- STATUS MODAL -->
        <IconTextModal
            :dialog="isShow"
            image="modal/warning.png"
            titleClass="fail-text"
            :title="failTitle"
            :description="errorMessage"
            :buttonText="$t('Button.tryAgain')"
            @action="failAction"
            :isCloseBtn="false"
        />

        <!-- FAQ MODAL -->
        <IconTextModal
            modalId="modal-verification-faq"
            :dialog="isShowVerificationFAQ"
            image="modal/info-circle.png"
            :title="$t('Header.FAQ')"
            :description="$t('Modal.verificationRemark')"
            @close="isShowVerificationFAQ = false"
        />
    </div>
</template>

<script>
export default { 
    props: {
        sendInfo: { type: Object },
        isFail: { type: Boolean },
    },

    data() {
        return {
            isLoading: false,
            submitDisabled: false,
            emailCode: "",
            twoFACode: "",
            emailCodeError: "",
            codeError: "",
            errorMessage: "",
            emailDisabled: false,
            emailTimerCount: 0,
            isShow: false,
            failTitle: "",
            mobileBtnText: this.$t("Verification.send"),
            emailBtnText: this.$t("Verification.send"),
            isShowVerificationFAQ: false,
        }
    },

    watch: {
        isFail: {
            handler() {
                if(this.isFail) {
                    this.submitDisabled = false;
                }
            }
        }
    },

    methods: {
        failAction() {
            this.isShow = false;
            this.submitDisabled = false;
        },

        async getEmailCode() {
            if(!this.emailDisabled) {
                this.isLoading = true;
                this.emailDisabled = true;
                this.isShow = false;
                
                let params = {
                    email: this.$store.state.user.email,
                    amount: this.sendInfo.amount,
                    symbol: this.sendInfo.coin,
                    toAddress: this.sendInfo.toAddress,
                    accountNumber: this.sendInfo.accountNumber,
                    bankName: this.sendInfo.bankName,
                    action: this.sendInfo.action,
                    platform: this.$store.state.preferences.platform == '1' ? 'MY' : 'WW',
                    request_id: this.sendInfo.request_id
                };

                this.$store.dispatch("user/requestEmailOtp", params)
                    .then(() => {
                        this.countDownEmailTimer();
                    })
                    .catch((error) => {
                        this.isShow = true;
                        this.failTitle = this.$t("Modal.failToObtainEmailCode");
                        this.errorMessage = this.errorCheck(error);
                        this.emailDisabled = false;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            }
        },

        countDownEmailTimer() {
            this.emailTimerCount = 60;
            let countdown = setInterval(() => {
                this.emailTimerCount -= 1;
                this.emailBtnText = this.$t("Verification.send") + " (" + this.emailTimerCount + "s)";
            }, 1000);

            setTimeout(() => {
                clearInterval(countdown);
                this.emailDisabled = false;
                this.emailBtnText = this.$t("Verification.send");
            }, 60000);
        },

        async paste() {
            try {
                this.twoFACode = await navigator.clipboard.readText();
            } catch (error) {
                this.codeError = this.$t("Verification.pasteError");
            }
        },

        submit() {
            let error = false;
            this.emailCodeError = "";
            this.codeError = "";

            // EMAIL CODE
            if(this.emailCode === "") {
                error = true;
                this.emailCodeError = this.$t("Verification.errorEmailCode");
            }

            // TWO FA
            if(this.twoFACode === "") {
                error = true;
                this.codeError = this.$t("Verification.errorGoogleCode");
            }

            if(!error) {
                this.submitDisabled = true;
                if(this.submitDisabled) {
                    this.$emit("submit", this.emailCode, this.twoFACode);
                }
            }
        }
    }
}
</script>

<style>
#verification .main-btn {
    margin-top: 3rem;
}

@media only screen and (max-width: 768px) {
    /* #verification .input-label {
        white-space: break-spaces;
    } */
    #verification .main-btn {
        margin-top: 1rem;
    }
}
</style>